import * as React from "react"
import { Container, Navbar, Nav } from "react-bootstrap"
import { navigate } from "gatsby-link"

type Props = {
  siteTitle: string
}

const Header = ({ siteTitle }: Props) => (
  <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
    <Container>
      <Navbar.Brand>{siteTitle}</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link
            onClick={() => {
              navigate("/")
            }}
          >
            Home
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              navigate("/collection/page/1/")
            }}
          >
            Collection
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              navigate("/roadmap/")
            }}
          >
            Roadmap
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              navigate("/artist/")
            }}
          >
            Artist
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

export default Header
